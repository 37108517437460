<template>


<spinner v-if="(data == null)&&(load == true) && (error == false)" />

  <airtime-transaction @pay-trans="pay" :data="data" :process="process"  v-else-if="(data != null) && (data.type == 1)"/>
   <data-transaction @pay-trans="pay" :data="data" :process="process"   v-else-if="(data != null) && (data.type == 2)"/>
  <cabletv-transaction @pay-trans="pay" :data="data" :process="process"   v-else-if="(data != null) && (data.type == 4)"/>
   <electricity-transaction @pay-trans="pay" :data="data" :process="process"   v-else-if="(data != null) && (data.type == 3)"/>
   <education-transaction @pay-trans="pay" :data="data" :process="process"   v-else-if="(data != null) && (data.type == 5)"/>
      <showmax-transaction @pay-trans="pay" :data="data" :process="process"   v-else-if="(data != null) && (data.type == 6)"/>

</template>


<script>
import AirtimeTransaction from'../components/transaction/AirtimeTransaction.vue'
import Spinner from '../components/Spinner.vue'
import DataTransaction from '../components/transaction/DataTransaction.vue'
import CabletvTransaction from '../components/transaction/CabletvTransaction.vue'
import ElectricityTransaction from '../components/transaction/ElectricityTransaction.vue'
import EducationTransaction from '../components/transaction/EducationTransaction.vue'
import ShowmaxTransaction from '../components/transaction/ShowmaxTransaction.vue'
import useServices from "@/auth/jwt/userServices"

export default {
    props: ['hash'],
    components: {
       AirtimeTransaction,
       DataTransaction, 
       ElectricityTransaction,
       ShowmaxTransaction,
       EducationTransaction,
       CabletvTransaction,    
       Spinner, 
    },
    data() {
        return {
            load: false,
            data: null,
            process: false,
            error: false,
        }
    },
    

      created() {
        this.gettransaction();
    },


    methods: {

    async  pay(hash){

    try {
               
           this.process = true;
            await useServices.paytransaction(hash).then((result)=> {
               
             console.log(result.data);



                 if (result.data.status == true) {
                this.data = result.data.data.transaction;
                  this.$swal({
                            title: 'Success!',
                            text: result.data.data.message,
                            icon: 'success',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })

               
            }else {

                    this.$swal({
                            title: 'Error!',
                            text: result.data.data.message,
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })



            }
            });

          

            this.process = false;

           } catch (error) {
                this.process = false;
            //   this.$router.back();

           }
      
      
      
      
      
      
      },





       async gettransaction()    {

           try {
               
           this.load = true;
            await useServices.getsingletransaction(this.hash).then((result)=> {
               this.data = result.data.data;
            });

            if (result.data.status == false) {
                // this.$router.back();
            }

            this.load = false;

           } catch (error) {
                this.load = false;
            //   this.$router.back();

           }

        }
    },
}
</script>

