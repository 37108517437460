<template>

          <b-tr>
              <b-td class="text-left"><h5 class="font-weight-bolder">{{title}}</h5></b-td>
              <b-td class="text-right">
               <slot></slot>
              </b-td>
           
            </b-tr>
           
    
</template>

<script>

import {  BRow,BTr, BTd, BCardText } from 'bootstrap-vue'
export default {
    props: ['title'],
     components: {
         BTd,
        BRow,
        BTr,
      BCardText,
    },
  
}
</script>