<template>
    <div>
    
        <b-row lass="vh-100 text-center" align-v="center">
            <b-col offset-sm-2 sm="12" md="10" offset-lg="2" lg="6" align-self="center" class="mx-auto">
                <b-card class="pricing-basic-value font-weight-bolder text-primary text-center" >
                  <b-img
   
      :src="mainurl + data.image"
      rounded="circle"
      height="60"
      width="60"
      alt="Top-rounded image"
      class="d-inline-block mr-1 mb-1"
    />
<h2>{{data.title}}</h2>

<b-row>

     <b-col col="6">
      <div class="demo-inline-spacing">
      
      </div>
    </b-col>
    <b-col col="6">
      <div class="demo-inline-spacing">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          block
          variant="primary"
            @click="$emit('pay-trans', data.hash)"
              :disabled="process"
              v-if="data.status == 1"
        >
        <b-spinner v-if="process"
          small
          type="grow"
        />  
            <span v-else>  Pay NGN {{data.amount_paid}}</span> 
        </b-button>
      </div>
    </b-col>







   
  </b-row>
                </b-card>


                 <b-card class="pricing-basic-value font-weight-bolder text-primary text-center "  style="padding: 0.8rem !important;">
                    <b-table-simple
          borderless
        >
      <b-tbody>

     
                
         
            <transaction-item title="Status">  

<h5 class="font-weight-normal"><b-badge :variant="statusVariant(data.status)">
            {{statusVariantText(data.status)}}
          </b-badge></h5>


            </transaction-item>
       <b-tr >
           <b-td colspan="2">
              <hr  class="invoice-spacing" style="margin-top: 0.2rem !important; margin-bottom: 0.2rem !important">
   
           </b-td>
           
                    </b-tr>  
            <transaction-item title="Hash"> 
               
<h5 class="font-weight-normal">{{data.code}}</h5>
            </transaction-item>
           
         <b-tr >
           <b-td colspan="2">
              <hr  class="invoice-spacing" style="margin-top: 0.2rem !important; margin-bottom: 0.2rem !important">
   
           </b-td>
           
                    </b-tr>  
                 
          

            <transaction-item title="Description">  
                <h5 class="font-weight-normal">{{data.description}}</h5>
            </transaction-item>
         <b-tr >
           <b-td colspan="2">
              <hr  class="invoice-spacing" style="margin-top: 0.2rem !important; margin-bottom: 0.2rem !important">
   
           </b-td>
           
                    </b-tr>  
            <transaction-item title="Package">   
                <h5 class="font-weight-normal">{{data.package}}</h5>
            </transaction-item>
 <b-tr >
           <b-td colspan="2">
              <hr  class="invoice-spacing" style="margin-top: 0.2rem !important; margin-bottom: 0.2rem !important">
   
           </b-td>
           
                    </b-tr>  
       <transaction-item title="Recipient">   
           <h5 class="font-weight-normal">{{data.recipient}}</h5>
       </transaction-item>

       
 <b-tr >
           <b-td colspan="2">
              <hr  class="invoice-spacing" style="margin-top: 0.2rem !important; margin-bottom: 0.2rem !important">
   
           </b-td>
           
                    </b-tr>  

        <transaction-item title="Date">  
          <h5 class="font-weight-normal">{{data.created_at |  moment("from", "now")}}</h5>  
  </transaction-item>
 <b-tr >
           <b-td colspan="2">
              <hr  class="invoice-spacing" style="margin-top: 0.2rem !important; margin-bottom: 0.2rem !important">
   
           </b-td>
           
                    </b-tr>  

      <transaction-item title="Product Amount"> 
          <h5 class="font-weight-normal">NGN {{data.amount}}</h5>
           </transaction-item>


                <b-tr v-if="data.meter_token != null" >
           <b-td colspan="2">
              <hr  class="invoice-spacing" style="margin-top: 0.2rem !important; margin-bottom: 0.2rem !important">
   
           </b-td>
           
                    </b-tr> 

            <transaction-item title="Token" v-if="(data.meter_token != null)"> 
          <h5 class="font-weight-normal">{{data.meter_token}}</h5>
           </transaction-item>
          
          
 </b-tbody>
                    </b-table-simple>
                     <h5 class="text-danger text-center mt-2" >
           {{data.note}}
                </h5>

                <div class="demo-inline-spacing">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          block
          variant="primary"
             @click="$emit('pay-trans', data.hash)"
              :disabled="process"
              v-if="data.status == 1"
        >
        <b-spinner v-if="process"
          small
          type="grow"
        />  
            <span v-else>  Pay NGN {{data.amount_paid}}</span> 
        </b-button>
      </div>
                </b-card>
               
            </b-col>
                  
            
            
        </b-row>
    
    
       
    </div>
</template>



<script>
import { BCard, BBadge, BSpinner, BTbody, BTr, BTableSimple, BTd, BCardText, BImg, BButton, BFormInput, BFormGroup,  BContainer, BRow, BCol } from 'bootstrap-vue'
import TransactionItem from '../TransactionItem.vue'
import Ripple from 'vue-ripple-directive'



import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
export default {
  props: ['data', 'process'],
    components: {
        BCard,
        BTr,
        BTableSimple,
        BSpinner,
        BBadge,
        BTd,
        BTbody,
        BImg,
        BContainer,
        BFormInput,
        BFormGroup,
        TransactionItem,
        BButton,
        BCardText,
        BRow,
        BCol,
      
    },
    data() {
        return {
            load: false,
            data: null,
              mainurl: jwtDefaultConfig.mainurl,
        }
    },
      directives: {
        Ripple,
    },
     computed: {
   
       statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
   
        3 : 'light-success',
        0    : 'light-danger',
        1    : 'light-warning',
        2     : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
      },


       statusVariantText() {
      const statusColor = {
        /* eslint-disable key-spacing */
   
        3 : 'Completed',
           0    : 'Cancelled',
        1    : 'Pending payment',
        2     : 'Processing',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
      }

  },
    

   


  
}
</script>

